import './App.css';
import Header from './Header';
import {Route, Routes} from 'react-router-dom';
import Layout from './Layout';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import AboutPage from './pages/AboutUs';
import RegisterPage from './pages/RegisterPage';
import ResearchPage from './pages/ResearchPage';
import { UserContextProvider } from './UserContext';
import CreatePostPage from './pages/CreatePost';
import MembersPageDB from './pages/MembersPageDB';
import NewsDB from './pages/NewsDB';
import PublishAPIPage from './pages/PublishPageAPI';
import NewsPost from './pages/NewsPost';
import EditPost from './pages/EditPost';

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='/home' element={<HomePage />} />
          <Route path='/newsdb' element={<NewsDB />}/>
          <Route path='/about' element={<AboutPage />}/>
          <Route path='/research' element={<ResearchPage />}/>
          <Route path='/login' element={<LoginPage />}/>
          <Route path='/register' element={<RegisterPage />}/>
          <Route path='/create' element={<CreatePostPage />}/>
          <Route path='/membersdb' element={<MembersPageDB />}/>
          <Route path='/publishapi' element={<PublishAPIPage />}/>
          <Route path='/post/:id' element={<NewsPost />}/>
          <Route path='/edit/:id' element={<EditPost />}/>
        </Route>
      </Routes>
    </UserContextProvider>
    
  );
}

export default App;
