import RE02 from './assets/RE021.png';
import RE03 from './assets/RE031.png';
import RE04 from './assets/RE04.png';
import RE06 from './assets/RE06.png';
import RE08 from './assets/RE08.png';
export default function ResearchPage() {
    return (
        <main>
            <div className="about-research-container">
                <h1>Research</h1>
                <h2 className="about-research-h2"><storng>Energy Transition and CCS</storng></h2>
                <p className="about-research-p">Anthropogenic CO<sub>2</sub> emissions have resulted in a major increase in atmospheric levels of CO<sub>2</sub> and other greenhouse gases. This is undeniably leading to global warming, through the greenhouse effect and also acidification of the oceans. There is therefore an inevitable need to reduce current and future emissions of CO<sub>2</sub> and other greenhouse gases. To comply with this, the Royal Thai Government has a plan to become net zero by 2065 as pledged by the former PM Prayut Chan-o-Cha at the UN Climate Change Conference (COP26) in Glasgow; and Chiang Mai University has also published the educational development plan for Phase 13 towards sustainable development with SDGs plans that includes SDG 13 “Climate Action” as ranked No. 1 in Thailand and 50th globally by the Times Higher Education Impact Rankings 2022.</p>
                <div className="sponser-logo-container">
                <div className="sponsors-logo">
                    <img src={RE02} alt='photo1' style={{width:'30vw',height:'auto', marginLeft: '4vw'}}/>
                    <img src={RE03} alt='photo2' style={{width:'30vw',height:'auto', marginLeft: '4vw'}}/>
                </div>
            </div>
                <p className="about-research-p" style={{textAlign:'center'}}>(<em>Image Sources: Thai PBS and CMU</em>)</p>
                <p className="about-research-p">Reaching net zero is a major challenge, requiring a re-building of much of the national and international infrastructure related to the energy industry. The challenges fall both in the supply of energy and the demand or use of energy.</p>
                <p className="about-research-p">The supply needs to change from oil, gas and coal to carbon-free sources, which with today’s technologies largely implies new wind and solar powers. Future technologies may also unlock more of the potential energy in waves, tides and geothermal for example. Presently over 80% of the world’s energy is supplied by fossil fuels, with hydroelectric and nuclear being another 14%, and so the transition in the energy supply system is of a truly global scale. <storng>While the transition occurs, energy supply needs to be maintained</storng>; given the scale of the infrastructure change, <storng>it is very likely that we will continue to use a gradually diminishing, but significant inventory of fossil fuels</storng>, as the new renewable supplies come on stream. This future inventory of fossil fuels will produce a substantial carbon emission; technologies to reduce these emissions by making the production or consumption of this fuel more efficient, while the energy transition is under way, can therefore have an important impact on overall atmospheric carbon levels.</p>
                <p className="about-research-p">The demand for energy spans many different uses, but major demands include cooling (for buildings), electricity for power (for use in lighting, powering computers, and other electrical goods), and energy for transport (cars, trucks, and planes). Presently in Thailand, transport presently is relied on petrol- and diesel-powered cars and trucks, and jet-fuel for planes. To transition to a zero-carbon system, we need new vehicles powered by carbon-free energy sources. Electrical cars provide a replacement for petrol/diesel cars, but large trucks, with substantially greater mass and hence energy needs, may require alternative high density energy sources, such as <storng>hydrogen</storng>, and there are experiments under way exploring the use of hydrogen to power transport. Hydrogen could be generated from renewable energy using the electricity to electrolyze water, thereby making the hydrogen a zero-carbon fuel. Electricity in Thailand might be increasingly produced by wind and solar power, and so with sufficient supply of wind turbines and solar farms, the electrical systems become zero carbon. However, in today’s power generation system, wind and solar do not provide all the electricity, and that fraction of the electricity derived from <storng>coal</storng> is not carbon-free; <storng>electric cars running on coal-derived electricity do not remove carbon emissions.</storng> </p>
                <p className="about-research-p">Another major challenge of the energy transition is that as well as requiring new infrastructure for both the supply and the demand systems, the electrical grid which transmits the electricity needs a substantial upgrade and redesign if it is to carry the energy for transport and heating as well as the electricity load for its present uses. </p>
                <p className="about-research-p">Owing to such tremendous challenges, a renowned technology which has been discussed and included in the IPCC and Thailand Greenhouse Gas Management Organization (TGO) plans is the process of <storng>Carbon Capture and Storage (CCS)</storng>. CCS involves the removal of carbon dioxide from the exhaust gases produced when fossil fuels are combusted. At a power station, the CO<sub>2</sub> can be captured on a large scale, and this CO<sub>2</sub> is then pumped underground about 1.5-2 km below the surface into depleted hydrocarbon fields or saline aquifers (layers of permeable rock), where the CO<sub>2</sub> will displace the water in the pore spaces in the rock, and then become trapped. CCS provides a means to prevent addition of CO<sub>2</sub> to the atmosphere, while still using natural gas or other fossil fuels to generate electricity, leading to the expression clean gas. CCS is however an important technology in its own right, since there are other industrial processes which produce large fluxes of CO<sub>2</sub>, for example the process of cement and steel manufactures. Using CCS to capture the large flux of CO<sub>2</sub> produced at a cement factory or other would reduce those emissions into the atmosphere. It is also possible to grow biofuels, which absorb CO<sub>2</sub> from the atmosphere, and then burn the fuels to produce electricity, while capturing the exhaust gases through CCS. This ultimately leads to negative emissions of CO<sub>2</sub>, and has been proposed as part of the net zero system for 2050.</p>
                <p className="about-research-p"><storng>Chiang Mai CCS</storng> is very keen to involve in the development of many of the above solutions related to the energy transition <storng>with fostering nation’s energy security</storng>. We have a major program of research into <storng>CCS</storng> at full value chain described below.  </p>
                <h2 className="about-research-h2"><storng>Our Flagship Focus</storng></h2>
                <p className="about-research-p">Determined to explore the whole full-chain CCS, <storng>Chiang Mai CCS</storng> has prioritized its research portfolio into 7 work packages (WP) for its first 3-year period with aim to contribute as properly as possible to the industry-led CCS ecosystem in the first initiating decade, as follows:</p>
                <div className='about-banner'>
                <img src={RE04} alt='banner'></img>
                </div>
                
                <p><em>WP1: Capture and Source Identification</em></p>
                <p className="about-research-p">Highly concentrated CO<sub>2</sub> “point sources” are to be identified throughout Thailand and related regions, of which the following capture technologies are to be selected and retrofitted to the existing facilities, such as hard-to-abate fossil-based power plants and cement plants. Knowing the source and its characterization and quantity are crucial for capturing design and to be matched with the sink or storage sites.</p>
                <p className="about-research-p">CO<sub>2</sub> capture technology is also of great importance to be investigated since the capture cost dominates how expensive the CCS is. New technologies or innovations to reduce the capture cost with great effective is of most challenge to make CCS more affordable.</p>
                
                <p><em>WP2: Transport with Utilization</em></p>
                <p className="about-research-p">Mode of transportation from source to sink is to be optimized with help of an AI. Proper state of CO<sub>2</sub> (e.g., gas, liquid or supercritical) to be transported is also investigated at specific source-sink clustering with quantity realized. Transportation network for Thailand and the regions are to be designed with dynamic visualization.</p>
                <p className="about-research-p">Utilizing CO<sub>2</sub> while transport is other interest area to be examined to compensate the CCS cost.</p>
                
                <p><em>WP3: Physical Geological Storage</em></p>
                <p className="about-research-p">Storage containment and integrity is emphasized, especially in specific field or formation of interest. CO<sub>2</sub> trapping mechanisms are elucidated including structural & stratigraphic trap, residual trap, and solubility trap. Storage improvement through each trapping is to be investigated across scales. </p>
                <p className="about-research-p">The package includes numerical simulation for storage reservoir at field scale as well as injection design and well integrity.</p>

                <p><em>WP4: Geochemical Geological Storage (Mineralization)</em></p>
                <p className="about-research-p">Mineralization trapping is a focus in this WP, including unconventional geochemistry-driven storage – such as basaltic rocks. Resource assessment and storage acceleration is a prime investigation.</p>

                <p><em>WP5: Storage Capacity Quantification</em></p>
                <p className="about-research-p">Including geological assessment at regional to basin scales, storage site identification and capacity estimate are main tasks of this WP based on geophysical fundamental using seismic survey and numerical simulation. Key storing basins are to be further reassessed toward site selection scale.</p>

                <p><em>WP6: Techno-economic and Incentive</em></p>
                <p className="about-research-p">Given a rather expensive climate mitigation approach, CCS needs a proper financial scheme coming with actual and up-to-date techno-economic analysis. The WP also investigates how the CCS should be financed, either privately or publicly, and even how the financial scheme should be developed at each time period. </p>

                <p><em>WP7: Measurement, Monitoring, and Verification (MMV)</em></p>
                <p className="about-research-p">A very important WP to verify how the CO<sub>2</sub> plume injected migrates underneath in a period of time using both current seismic technology and emerging fiber-optics counterpart. The WP will set and serve as a regulatory tool to verify if the injection secure and permanent. </p>

                <h2 className="about-research-h2"><storng>In Support of SDGs</storng></h2>
                <p className="about-research-p">To tackle this Energy Transition with the UNs Sustainable Development Goals (SDGs), Chiang Mai CCS is proud to contribute to main 3 SDGs relevant to the CCS.</p>
                <h3 className="about-research-h3"><em>SDG 7 (Affordable and Clean Energy)</em>: Our research helps to secure energy security and energy affordability. We believe that hard-to-abate energy sources (e.g., fossil-based) are in need as they are cheaper and this is recognized as main energy sources for emerging economies, like Thailand and other ASEAN. Through CCS deployment, fossil-based fuels or carbon-based renewables (e.g., biomass) are to be cleaned with releasing CO<sub>2</sub> captured.</h3>
                <h3 className="about-research-h3"><em>SDG 9 (Industry, Innovation and Infrastructure)</em>: Our CCS research is to support the emerging new green economy – the CCS economy. When CCS is implemented, a large full-chain industry will rise and more jobs created to support the value chain. New infrastructure will be constructed while R&D including innovation toward energy-environment issues are of focus. </h3>
                <h3 className="about-research-h3"><em>SDG 13 (Climate Action)</em>: CCS is not an option, but a must. To fight this climate change, we need gigaton-scale CO<sub>2</sub> and other greenhouse gases removed permanently and securely from atmosphere while no other technologies are likely capable of. As a main SDG, Chiang Mai CCS consolidates its position to dedicate its resources to contribute to such a challenge. </h3>

                <h2 className="about-research-h2">Key Research Facilities</h2>
                <p className="about-research-p">In addition to access to university facilities and other national laboratories, we are proud to have some of state-of-the-art research facilities matching our expertise. </p>
                <h3 className="about-research-h3"><em>High-pressure coreflooding setup for CO<sub>2</sub> storage</em>: Fist public research facility in Thailand to house this kind of facility, allowing great questions on storage capacity, geochemistry influence, and storage improvement are to be addressed. Supercritical CO<sub>2</sub> and CO<sub>2</sub>-EOR are potentially main interest. </h3>
                <h3 className="about-research-h3"><em>Optical tensiometer with high-speed camera</em>: Interfacial phenomena are key mechanisms in multiphase flow in porous media – such as occurring in CO<sub>2</sub> injection into deep porous formation. Our optical tensiometer allows us to elucidate the mechanism across scales from sub-pore scale to core-scale. </h3>
                <h3 className="about-research-h3"><em>Commercial reservoir simulators</em>: With great generous donations from commercial developers (i.e., SLB and Computer Modelling Group Ltd.), we have accessed to main simulators used in the field of reservoir engineering. Specifically simulated to research activities, our studies will be at real as industrial and can be interchangeable with our industrial and academic counterparts.</h3>
                <div className="sponsors-logo">
                    <img src={RE06} alt='photo1' style={{width:'20vw',height:'auto', marginLeft: '18vw'}}/>
                    <img src={RE08} alt='photo2' style={{width:'20vw',height:'auto', marginLeft: '0vw'}}/>
                </div>
                <p className="about-research-p"><em>Dedicated saver and high-performance computer</em>: Provision of both a dedicated saver and high-performance computer (HPC) represents a significant facility for researchers specializing in machine learning/deep learning and simulation, where georesources problems need. These advanced computing resources are specifically tailored to meet their complex computational requirements, enabling them to explore intricate models and algorithms with precision. Both powerful computers can benefit researchers not only in running complex models or simulations (as reservoir complexes), but also in terms of reducing computation time.</p>
            </div>
        </main>
        
    )
}