export default function MembersPGTPL({full_name,position,department,email,img_path}) {
    return (
        <div>
            <div className="grid-wrap">
                <div className="member-item">
                    <img src={require(""+img_path)} />
                    <h3 className="member-name">{full_name}</h3>
                    <p className="member-detail">{position}</p>
                    <p className="member-detail">{department}</p>
                    <p className="member-detail">{email}</p>
                </div>
            </div>
        </div>)
};
