import { React, useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { Navigate, useParams } from "react-router-dom";
import { useContext, useEffect} from "react";
import { UserContext } from "../UserContext";
import Editor from "./Editor";
import parse from "html-react-parser";

const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };
const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];


export default function EditPost() {
    const {id} = useParams();
    const [title,setTitle] = useState('');
    const [summary,setSummary] = useState('');
    const [content,setContent] = useState('');
    const [files,setFiles] = useState('');
    const [redirect,setRedirect] = useState(false);
    const [images,setImages] = useState('');

    const api_get = `https://api-secure-petroleum-climate.eng.cmu.ac.th/post/${id}`;
    // const api_get = `http://localhost:4000/post/${id}`;
    useEffect(() => {
        fetch(api_get).then(response => {
                response.json().then(postInfo => {
                    setTitle(postInfo[0].title);
                    setContent(postInfo[0].content);
                    setSummary(postInfo[0].summary);
                })
            });
    }, []);

    async function updatePost(ev) {
        ev.preventDefault();
        const data = new FormData();
        data.set('title', title);
        data.set('summary',summary);
        data.set('content', content);
        data.set('id', id);
        if (files?.[0]) {
            data.set('files', files[0]);
            data.set('cover',true)
        }

        if (images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                data.append("images", images[i]);
            }
            data.set('img',true)
        }

        const api_post = 'https://api-secure-petroleum-climate.eng.cmu.ac.th/post'
        // const api_post = 'http://localhost:4000/post'
        const response = await fetch(api_post, {
            method: 'PUT',
            body: data,
        });
        if (response.ok) {
            setRedirect(true);
        }
    }

    if (redirect) {
        return <Navigate to={'/post/'+id} />
    }

    

    return (
        <form onSubmit={updatePost} className="create-post">
            <input type="title" 
                   placeholder={"Title"} 
                   value={title} 
                   onChange={ev => setTitle(ev.target.value)}/>
            <input type="summary" 
                   placeholder={"Brief Detail"}
                   value={summary}
                   onChange={ev => setSummary(ev.target.value)}/>
            <input type="file"
                   onChange={ev => setFiles(ev.target.files)}/>
            <ReactQuill value={content}
                        onChange={newValue => setContent(newValue)} 
                        modules={modules} 
                        formats={formats} className="react-quill-box"/>
            <input type="file" multiple
                   onChange={ev => setImages(ev.target.files)}/>
            <button style={{marginTop:'5px'}}>Update post</button>
        </form>
    )
}