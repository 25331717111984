import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "./UserContext";
import CCS_logo from './assets/CCS_logo2.png';

export default function Header() {
  const {setUserInfo,userInfo} = useContext(UserContext);
  const api_profile = 'https://api-secure-petroleum-climate.eng.cmu.ac.th/profile';
  // const api_profile = 'http://localhost:4000/profile';
  const api_logout = 'https://api-secure-petroleum-climate.eng.cmu.ac.th/logout';
  // const api_logout = 'http://localhost:4000/logout';
    useEffect(() => {
      fetch(api_profile, {
        credentials: 'include',
      }).then(response => {
        if (response.ok) {
          response.json().then(userInfo => {
            setUserInfo(userInfo)
          });
        }
      });
    }, []);

    // var responseClone;

    // useEffect(() => {
    //   fetch(api_profile, {
    //     credentials: 'include',
    //   }).then(response => {
    //     responseClone = response.clone();
    //     return response.json();
    //   }).then(userInfo => {
    //     setUserInfo(userInfo)
    //   }, function (rejectionReason) {
    //     console.log('Error parsing JSON from response:', rejectionReason, responseClone);
    //     responseClone.text()
    //     .then(function (bodyText) {
    //       console.log('Received the following instead of valid JSON:', bodyText);
    //     });
    //   });
    // }, [])

    function logout() {
      fetch(api_logout,{
        credentials:'include',
        method: 'POST',
      });
      setUserInfo(null);
    }

    const username = userInfo?.username;
    // console.log(username)

    return(
      <header>
        <Link to="/" className="logo">
          <div className="logo-wrap">
            <img src={CCS_logo}/>
          </div>
        </Link>
        <nav>
        {!username && (
            <>
            </>
          )}
          <Link to="/research">Research</Link>
          <Link to="/newsdb">News</Link>
          <Link to="/membersdb">Members</Link>
          <Link to="/publishapi">Publications</Link>
          <Link to="/about">About us</Link>
          {username && (
            <>
              <Link to={'/create'}>Create new post</Link>
              <Link to={'/'} onClick={logout}>Log out</Link>
            </>
          )}
        </nav>
      </header>
    )
}