import { useState } from "react"
import {Navigate} from "react-router-dom"

export default function RegisterPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const[redirect,setRedirect] = useState(false);

    const api_regis = 'https://api-secure-petroleum-climate.eng.cmu.ac.th/register';
    // const api_regis = 'http://localhost:4000/register';
    async function register(ev) {
        ev.preventDefault();
        const response = await fetch(api_regis, {
            method: 'POST',
            body: JSON.stringify({username,password,firstname,lastname,email}),
            headers: {'Content-Type':'application/json'} 
        });
        if (response.status === 200) {
            alert('registration sucessful');
            setRedirect(true);
        } else {
            alert('registration failed');
        }
    }

    if (redirect) {
        return <Navigate to={'/login'}/>
    }

    return (
        <form className="register" onSubmit={register}>
            <h1>Register</h1>
            <input type="text"
                   placeholder="firstname"
                   value={firstname}
                   onChange={ev => setFirstname(ev.target.value)}/>
            <input type="text"
                   placeholder="lastname"
                   value={lastname}
                   onChange={ev => setLastname(ev.target.value)}/>
            <input type="email"
                   placeholder="email"
                   value={email}
                   onChange={ev => setEmail(ev.target.value)}/>
            <input type="text" 
                   placeholder="username" 
                   value={username}
                   onChange={ev => setUsername(ev.target.value)}/>
            <input type="password" 
                   placeholder="password" 
                   value={password}
                   onChange={ev => setPassword(ev.target.value)}/>
            <button type="submit">Register</button>
        </form>
    )
}