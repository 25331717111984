import parse from 'html-react-parser';
import { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom";
import { UserContext } from '../UserContext';
import { FaPenToSquare } from 'react-icons/fa6';

export default function NewsPost() {
    const [postInfo,setPostInfo] = useState(null);
    const {userInfo} = useContext(UserContext);
    const {id} = useParams();
    const api = `https://api-secure-petroleum-climate.eng.cmu.ac.th/post/${id}`;
    // const api = `http://localhost:4000/post/${id}`;
    useEffect(() => {
        fetch(api).then(response => {
            response.json().then(postInfo => {
                setPostInfo(postInfo);
            })
        })
    }, [])

    if (!postInfo) return '';

    let contentImg = ''
    if (postInfo[0].content_img !== null) {
        contentImg = postInfo[0].content_img.split(',')
    } else {
        contentImg = false
    }

    return (
        <div className='news-container'>
            <h2 className='news-title'>{postInfo[0].title}</h2>
            {userInfo.id === postInfo[0].author_id && (
                <div className="edit-row">
                    <Link className='edit-btn' to={`/edit/${postInfo[0].post_id}`}>
                        <FaPenToSquare color='white'/> Edit this post
                    </Link>
                </div>
            )}
            <div className='cover-img-container'>
                <img className="news-cover-img" src={require(""+postInfo[0].cover_img)} />
            </div>
            <p className='news-content'>{parse(postInfo[0].content)}</p>
            <div className='content-img-container'>
                {contentImg && contentImg.map(image => (
                    <img className='content-img' src={require(""+image)} />
                ))}
            </div>
        </div>
    )
}