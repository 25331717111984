import React, { useEffect, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';
import { Link } from 'react-router-dom';



export default function Slider() {
    window.addEventListener('error', e => {
        if(e.message === 'ResizeObserver loop completed with undelivered notifications.' || 
            e.message === 'ResizeObserver loop limit exceeded') {
            e.stopImmediatePropagation();
            ResizeObserver.disconnect();
        }
    })

    const [posts,setPosts] = useState([]);
    const api = 'https://api-secure-petroleum-climate.eng.cmu.ac.th/postthird';
    // const api = 'http://localhost:4000/postthird';
    useEffect(() => {
        fetch(api).then(response => {
            response.json().then(posts => {
                setPosts(posts);
            })
        })
    }, [])

    let post_first_3 = []
    let post_next_3 = []

    for(let i=0; i<posts.length; i++) {
        if(i<3) {
            post_first_3.push(posts[i])
        } else {
            post_next_3.push(posts[i])
        }
    }

  return (
    <div className='news-event-box'>
        <div className='news-first-six'>
        <div className="slide-container">
            <Fade>
                {post_first_3.map((post, index) => (
                <div key={index} className='slide-detail'>
                    <img className='slide-img' src={require(''+post.cover_img)}/>
                    <Link to={`/post/${post.post_id}`} className='slide-caption'>{post.title} [Read more...]</Link>
                </div>
                ))}
            </Fade>
        </div>
        {/* <div className='news-next-three'>
            {post_next_3.map((post, index) => (
                <div key={index} className='news-each-three-title'>
                    <p className='news-each-caption'>{post.title} <Link to={`/post/${post.post_id}`} className='link-each-news'>Read more...</Link></p>
                </div>
            ))}
        </div> */}
    </div>
    </div>
  )

      
}

// src={require('./assets/uploads/0a1cffeb7d1a43bf48fcd4b2e3d7f1bb.jpg')