import { useContext, useState } from "react"
import {Navigate} from "react-router-dom"
import { UserContext } from "../UserContext";

export default function LoginPage() {
    // const [username,setUsername] = useState('');
    // const [password,setPassword] = useState('');
    // const[redirect,setRedirect] = useState(false);
    // const {setUserInfo} = useContext(UserContext);
    // async function login(ev) {
    //     ev.preventDefault();
    //     const response = await fetch('http://10.10.12.31:4000/login', {
    //         method: 'POST',
    //         body: JSON.stringify({username, password}),
    //         headers: {'Content-type':'application/json'},
    //         credentials: 'include',
    //     });
    //     if (response.ok) {
    //         response.json().then(userInfo => {
    //             setUserInfo(userInfo);
    //             setRedirect(true);
    //         })
            
    //     } else {
    //         alert('wrong credentials');
    //     }
    // }

    // if (redirect) {
    //     return <Navigate to={'/'}/>
    // }

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {setUserInfo} = useContext(UserContext);
    const[redirect,setRedirect] = useState(false);
    const api_login = 'https://api-secure-petroleum-climate.eng.cmu.ac.th/login';
    // const api_login = 'http://localhost:4000/login';
    async function login(ev) {
        ev.preventDefault();
        const response = await fetch(api_login, {
            method: 'POST',
            body: JSON.stringify({username,password}),
            headers: {'Content-Type':'application/json'} 
        });
        if (response.status === 200) {
                response.json().then(userInfo => {
                    setUserInfo(userInfo);
                    setRedirect(true);
                })
            } else {
            alert('login failed');
        }
    }

    if (redirect) {
            return <Navigate to={'/'}/>
        }

    return (
        <form className="login" onSubmit={login}>
            <h1>Login</h1>
            <input type="text" 
                   placeholder="username" 
                   value={username} 
                   onChange={ev => setUsername(ev.target.value)}/>
            <input type="password" 
                   placeholder="password" 
                   value={password}
                   onChange={ev => setPassword(ev.target.value)}/>
            <button type="submit">Login</button>
        </form>
    )
}