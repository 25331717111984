import { useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { Navigate } from "react-router-dom";
import { useContext, useEffect} from "react";
import { UserContext } from "../UserContext";

const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
  };
const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
  ];

export default function CreatePostPage() {
    const {setUserInfo,userInfo} = useContext(UserContext);
    const api = 'https://api-secure-petroleum-climate.eng.cmu.ac.th/profile';
    // const api = 'http://localhost:4000/profile';
    useEffect(() => {
      fetch(api, {
        credentials: 'include',
      }).then(response => {
        response.json().then(userInfo => {
          setUserInfo(userInfo)
        });
      });
    }, []);

    const [title,setTitle] = useState('');
    const [summary,setSummary] = useState('');
    const [content,setContent] = useState('');
    const [files,setFiles] = useState('');
    const [redirect,setRedirect] = useState('');
    const [images,setImages] = useState('');
    
    async function createNewPost(ev) {
        const data = new FormData();
        data.set('title', title);
        data.set('summary',summary);
        data.set('content', content);
        data.set('file', files[0]);
        data.set('author', userInfo.id)

        if (images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                data.append("images", images[i]);
            }
            data.set('img',true)
        }
        ev.preventDefault();

        const api_post = 'https://api-secure-petroleum-climate.eng.cmu.ac.th/post';
        // const api_post = 'http://localhost:4000/post';
        const response = await fetch(api_post, {
            method: 'POST',
            body: data,
        });
        if (response.ok) {
            setRedirect(true);
        }
        // console.log(response)

    }
    
    // if (redirect) {
    //     return <Navigate to={'/'} />
    // }
    return (
        <form onSubmit={createNewPost} className="create-post">
            <input type="title" 
                   placeholder={"Title"} 
                   value={title} 
                   onChange={ev => setTitle(ev.target.value)}/>
            <input type="summary" 
                   placeholder={"Brief Detail"}
                   value={summary}
                   onChange={ev => setSummary(ev.target.value)}/>
            <input type="file"
                   onChange={ev => setFiles(ev.target.files)}/>
            <ReactQuill value={content}
                        onChange={newValue => setContent(newValue)} 
                        modules={modules} 
                        formats={formats} className="react-quill-box"/>
            <input type="file" multiple
                   onChange={ev => setImages(ev.target.files)}/>
            <button style={{marginTop:'5px'}}>Create post</button>
        </form>
    )
}