import { useEffect, useState } from 'react';
import MembersPITPL from './MembersPITPL';
import MembersPGTPL from './MembersPGTPL';
import MembersUDTPL from './MembersUDTPL';

export default function MembersPageDB() {
    const [members,setMembers] = useState([]);
    const api = 'https://api-secure-petroleum-climate.eng.cmu.ac.th/memberlist';
    // const api = 'http://localhost:4000/memberlist';
    useEffect(() => {
        fetch(api).then(response => {
            response.json().then(members => {
                setMembers(members);
		// console.log(posts)
            })
        })
    }, []);

    var pi_members = members.filter(element => element.member_type === "PI");
    var pg_members = members.filter(element => element.member_type === "PG");
    var ud_members = members.filter(element => element.member_type === "UD");
    var al_members = members.filter(element => element.member_type === "AL");

    return (
            <div>
                <h1>Principle Investigators (PIs)</h1>
                <div className='grid-wrap'>
                    {pi_members.length > 0 && pi_members.map(member => (
                        <MembersPITPL {...member}/>
                    ))}
                </div>
                <h1>Postgraduate Researchers</h1>
                <div className='grid-wrap'>
                    {pg_members.length > 0 && pg_members.map(member => (
                        <MembersPGTPL {...member}/>
                    ))}
                </div>
                <h1>Undergraduate Students</h1>
                <div className='grid-wrap'>
                    {ud_members.length > 0 && ud_members.map(member => (
                        <MembersUDTPL {...member}/>
                    ))}
                </div>
                <h1>Alumni</h1>
                <div className='alumni-tab-container'>
                    <table className='alumni-table'>
                        <tr>
                            <th className='alumni-tab-head'>Name</th>
                            <th className='alumni-tab-head'>Department</th> 
                            <th className='alumni-tab-head'>Year of graduation</th> 
                            <th className='alumni-tab-head'>Thesis/Project Name</th> 
                        </tr>
                        {al_members.length > 0 && al_members.map(member => (
                            <tr className='alumni-row'>
                                <td className='alumni-tab-row'>{member.full_name}</td>
                                <td className='alumni-tab-row-center'>{member.department}</td>
                                <td className='alumni-tab-row-center'>{member.year_grad}</td>
                                <td className='alumni-tab-row'>{member.thesis_name}</td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>)
};