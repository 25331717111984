import './App.css';

export default function Footer() {
    return (
        <footer>
            <p className='footer-p-vision'>Principle National Research Center for Full-chain CCS to Ensure Our Energy Transition Secure and Sustainable.</p>
            {/* <p className='footer-p-text'>Chiang Mai Carbon Capture and Storage</p> */}
            <p className='footer-p-copyright'>Copyright &copy; 2023 Chiang Mai CCS, All Rights Reserved.</p>
        </footer>
    )  
}
// ศูนย์วิจัยหลักของประเทศด้านการดักจับและกักเก็บคาร์บอนไดออกไซด์ทั้งห่วงโซ่คุณค่า พร้อมเชื่อมโยงระหว่างภูมิภาค