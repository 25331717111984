import { useEffect, useState } from 'react';
import NewsTPL from './NewsTPL';
import CCS_logo from './assets/CCS_logo.png';

export default function NewsDB() {
    const [posts,setPosts] = useState([]);
    const api = 'https://api-secure-petroleum-climate.eng.cmu.ac.th/post';
    // const api = 'http://localhost:4000/post';
    useEffect(() => {
        fetch(api).then(response => {
            response.json().then(posts => {
                setPosts(posts);
            })
        })
    }, [])
    return (
      <>
        <h1>News</h1>
        {posts.length > 0 && posts.map(post => (
            <NewsTPL key={post.post_id} {...post} />
        ))}
      </>
    );
}