import CCS_banner from './assets/Banner.png';
import MapCCS from "./Map";
import Slider from "./news-slider";


export default function HomePage() {
    return (
        <main>
            {/* <h1>Announcements</h1> */}
            <div className="banner-container">
                <img src={CCS_banner} className="ccs-banner"/>
            </div>
            <div className="news-container-home">
                <h1>News & Events</h1>
                <Slider />
            </div>
            <div className="contact-container-home">
                <h1>Contact us</h1>
                <div className="contact-container">
                    <div className="map-container">
                        <MapCCS />
                    </div>
                    <div className="contact-info">
                        <p className="contact-text-ccs">Chiang Mai CCS</p>
                        <p className="contact-text-ccs">(Chiang Mai Research Group for Carbon Capture and Storage)</p>
                        <p className="contact-text">Chiang Mai University</p>
                        <p className="contact-text">Chiang Mai 52000, Thailand</p>
                        <p className="contact-text">Tel: 053-944128</p>
                        <p className="contact-text">Fax: 053-944186</p>
                        <p className="contact-text">E-mails: suparit.t@cmu.ac.th, natthanan.p@cmu.ac.th</p>
                    </div>
                </div>
            </div>
        </main>
    )
}

// Chaing Mai Research Group for Carbon Capture and Storage (Chaing Mai CCS) Chaing Mai University 50200 (Department of Mining and Petroleum) 
// Tel : 053-944128, 944188
// Fax 053-944186
// E-mail : suparit.t@cmu.ac.th, natthanan.p@cmu.ac.th