import parse from 'html-react-parser';
import { FaLink } from 'react-icons/fa'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import data from './assets/scopusapi.json'

let user_id = [
            {"name":"Chatchawan Chaichana","scopus_id":"6505945297"},
            {"name":"Cheowchan Leelasukseree","scopus_id":"25646612400"},
            {"name":"Jirakom Sirisrisakulchai","scopus_id":"56105039600"},
            {"name":"Komsoon Somprasong","scopus_id":"57209429956"},
            {"name":"Mingkhwan Kruachanta","scopus_id":"56964483800"},
            {"name":"Nakorn Tippayawong","scopus_id":"23979068100"},
            {"name":"Natthanan Promsuk","scopus_id":"57202821797"},
            {"name":"Pisanu Wongpornchai","scopus_id":"16240292900"},
            {"name":"Ramnarong Wanison","scopus_id":"57208864755"},
            {"name":"Santi Phithakkitnukoon","scopus_id":"23398014000"},
            {"name":"Sirasit Srinuanpan","scopus_id":"56321632000"},
            {"name":"Suparit Tangparitkul","scopus_id":"57201121715"},
            {"name":"Tadsuda Taksavasu","scopus_id":"57203458960"},
            {"name":"Tinnakorn Phongthiya","scopus_id":"57324141200"},
            {"name":"Yothin Chimupala","scopus_id":"26421925700"},
            ];

let currentYear = `${new Date().getFullYear()}`
let yearpub = [`${currentYear}`, `${currentYear - 1}`, `${currentYear - 2}`, `${currentYear - 3}`]
// let yearpub = ['2023', '2022', '2021']


export default function PublishAPIPage() {

    const data_scopus = data
    const author_scopus = user_id

    // for (let i = 0; i < author_scopus.length; i++) {
    //     author_scopus[i].num_publish = data_scopus.filter((data) => data.user === author_scopus[i].scopus_id).length
    // }
    // console.log(author_scopus)

    // // if (data_scopus.length === 0) {return <h3>loading</h3>}

    const [yearFilter, setYearfilter] = useState([])
    const [authorFilter, setAuthorfilter] = useState([])

    const yearFilterHandler = (ev) => {
        if (ev.target.checked) {
            setYearfilter([...yearFilter, ev.target.value])
        } else {
            setYearfilter(yearFilter.filter((filter) => filter !== ev.target.value))
        }
    }

    const authorFilterHandler = (ev) => {
        if (ev.target.checked) {
            setAuthorfilter([...authorFilter, ev.target.value])
        } else {
            setAuthorfilter(authorFilter.filter((filter) => filter !== ev.target.value))
        }
    }

    var filters = {"year":yearFilter,"user":authorFilter};
    let data_filter = []
    if (filters.year.length > 0 && filters.user.length > 0) {
        data_filter = data_scopus.filter(o => Object.keys(filters).every(k => filters[k].some(f => o[k] === f)));
    } else if (filters.year.length === 0 && filters.user.length === 0) {
        data_filter = data_scopus
    } else {
        data_filter = data_scopus.filter(o => Object.keys(filters).some(k => filters[k].some(f => o[k] === f)))
    }

    let num_per_year = []
    for (let i = 0; i<yearpub.length; i++) {
        num_per_year.push({"year":yearpub[i],"num_pub":data_scopus.filter(data => data.year.includes(yearpub[i])).length})
    }
    let dataCatYr = [];
    let i = 0;
    
    if (yearFilter.length === 0) {
        for(i; i<yearpub.length; i++) {
            let j = 0;
            let subdata = [];
            for(j; j<data_filter.length; j++) {
                if(data_filter[j]["year"] === yearpub[i]) {
                    subdata.push(data_filter[j])
                }
            }
            subdata = subdata.filter((thing, index, self) => 
            index === self.findIndex((t) => t.title === thing.title && t.author === thing.author));
            dataCatYr.push({"year":yearpub[i],"publist":subdata})  
        }
    } else {
        for(i; i<yearFilter.length; i++) {
            let j = 0;
            let subdata = [];
            for(j; j<data_filter.length; j++) {
                if(data_filter[j]["year"] === yearFilter[i]) {
                    subdata.push(data_filter[j])
                }
            }
            subdata = subdata.filter((thing, index, self) => 
            index === self.findIndex((t) => t.title === thing.title && t.author === thing.author));
            dataCatYr.push({"year":yearFilter[i],"publist":subdata})  
        }
    }
    
    // console.log(yearFilter,dataCatYr)

    return (
        // <>2</>
        <div className='publish-page'>
            <div className='publish-data'>
                {dataCatYr.length > 0 && dataCatYr.map(container => (
                    <>
                    <h1 className='publish-year'>{container.year}</h1>
                    {container.publist.length > 0 && container.publist.map(item => (
                        <div className='publish-container'>
                            {/* <h3 className='publish-author'>{item.author}</h3> */}
                            <div className='publish-p-container'>
                                <h3 className='publish-author'>{parse(item.title.replace(/<inf[^>]*>/g,'<sub>').replace(/<\/inf>/g,'</sub>'))}</h3>
                                <p className='publish-p'>{item.pub}, {item.vol}</p>
                            </div>
                            <Link to={item.link} className='publish-link'>
                                <FaLink color='#4e618f' size={'1.5vw'}/>
                            </Link>
                        </div>
                    ))}
                    </>
                ))}
            </div>
            <div className='publish-filter'>
                <h3 className='filter-tag'>Year</h3>
                {num_per_year.length > 0 && num_per_year.map(item => (
                    <div className='filter-container'>
                        <label htmlFor={item.year} style={{display:'flex'}}>
                            <span className='filter-choice'>{item.num_pub}</span>
                            <input className='filter-box' 
                            type='checkbox' 
                            id={item.year} 
                            name={item.year} 
                            value={item.year}
                            onChange={yearFilterHandler}/> 
                            <span className='filter-choice'>{item.year}</span>
                        </label>
                    </div>
                ))}
                <h3 className='filter-tag'>Author</h3>
                {author_scopus.length > 0 && author_scopus.map(author => (
                    <>
                        <div className='filter-container'>
                            <label htmlFor={author.scopus_id} style={{display:'flex'}}>
                                {/* <span className='filter-choice'>{author.num_publish}</span> */}
                                <input className='filter-box' 
                                type='checkbox' 
                                id={author.scopus_id} 
                                name={author.scopus_id} 
                                value={author.scopus_id}
                                onChange={authorFilterHandler}/> 
                                <span className='filter-choice'>{author.name}</span>
                            </label>
                        </div>
                    </>
                ))}
            </div>
        </div>
    )
    
}
