import { Link } from 'react-router-dom';
import CCS_logo from './assets/CCS_logo.png';

export default function NewsTPL({post_id,title,summary,content,cover_img}) {
    return (
      <div className='newsdb-container'>
        <div className="post">
          <div className="newsdb-cover">
            <Link to={`/post/${post_id}`}>
              <img className="cover-img" src={require(""+cover_img)} />
            </Link>
          </div>
          <div className="texts">
            <Link to={`/post/${post_id}`}>
              <h2 className='newsdb-title'>{title}</h2>
            </Link>
            {/* <p className="summary">{summary}</p> */}
          </div>
        </div>
      </div>
        
    );
}