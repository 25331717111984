import { MapContainer, TileLayer, useMap, Marker, Popup} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import CCS_logo from './assets/CCS_logo.png';

export default function MapCCS() {
    return (
            <MapContainer center={[18.79569,98.95164]} zoom={16} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[18.79569,98.95164]}>
                    <Popup>
                    <img src={CCS_logo} />
                    </Popup>
                </Marker>
            </MapContainer>
    )
}
