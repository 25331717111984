import CMU_logo from './assets/CMU_logo.png';
import Jiangsu_logo from './assets/Jiangsu_logo.png';
import Leed_logo from './assets/Leed_logo.png';
import Murata_logo from './assets/Murata_logo.png';
import AB from './assets/AB01.png';
import Banner_copy from './assets/Banner - Copy.jpg';
import './AboutUs.css'

export default function AboutPage() {
    return (
        <div className='about-research-container'>
            <h1>About Us</h1>
            <div className='about-banner'>
            </div>     
            <p className="about-research-p">Interdisciplinary  research center established in 2023 with university support, Chiang Mai  Research Center for Carbon Capture and Storage or “<strong>Chiang Mai CCS</strong>” has  more than 20 academic members across various faculties within the university,  including other industrial visitors and governmental personnel who have been  involved in. We are seeking to collaborate with other domestic universities,  research centers, industries, and government agencies, and those international  counterparts, to facilitate and support the emerging of CCS technology and its  industry in the country and regions.</p>
            <p className="about-research-p">Formerly known as <em>CCS Frontier Research Group</em> and initiated by  Faculty of Engineering in 2022, Chaing Mai CCS has now expanded its own  research portfolio and work tirelessly with industries and other collaborators,  to ensure that we have high caliber research excellence concurrently with the  next generation researchers fitting into CCS industry.</p>
            <p className="about-research-p">We have a  wide range of researchers, from undergraduate students to post-doctoral  research associates, and offer some funded studentships and research fellows.  If you are interested in working or studying at our center, please contact the  relevant academic members.</p>
            <h2 className="about-research-h2"><storng>Our Mission</storng></h2>
            <p className="about-research-p">With a  vision to be a main Thailand’s research center for full-chain CCS, we are  playing a key role to bridge this technology in ASEAN with other regions. <strong>Chiang  Mai CCS</strong> has a strong ambition to <strong>bending the tree to lowering this  high-hanging fruit of CCS</strong> to be more practical and affordable in our  developing economics.</p>
            <div className='about-banner'>
                <img src={AB} alt='banner'></img>
            </div>
            <h2 className="about-research-h2"><storng>Our Fundings and Industrial Partners</storng></h2>
            <p className="about-research-p">Our research and activities have been generously supported by:</p>
            <div className="sponser-logo-container">
                <div className="sponsors-logo">
                    <img src={CMU_logo} alt='photo1' style={{width:'15vw',height:'auto', marginLeft: '2vw'}}/>
                    <img src={Jiangsu_logo} alt='photo2' style={{width:'15vw',height:'auto', marginLeft: '2vw'}}/>
                    <img src={Leed_logo} alt='photo3' style={{width:'15vw',height:'auto', marginLeft: '2vw'}}/>
                    <img src={Murata_logo} alt='photo4' style={{width:'15vw',height:'auto', marginLeft: '2vw'}}/> 
                </div>
            </div>
            <h2 className="about-research-h2"><storng>Diversity, Equity & Inclusion</storng></h2>
            <p className="about-research-p">At <strong>Chiang Mai CCS</strong>, we are committed to fostering a diverse, equitable, and inclusive environment that encourages innovation, collaboration, and excellence in research. We believe that diverse perspectives, backgrounds, and experiences contribute to the richness of our intellectual community and enhance the quality and impact of our work.</p>
            <p className="about-research-p"><u>Diversity</u>: We value and celebrate diverse perspectives, backgrounds, and experiences.</p>
            <p className="about-research-p"><u>Equity</u>: We ensure equal opportunities and eliminate barriers to support all members of our community.</p>
            <p className="about-research-p"><u>Inclusion</u>: We actively promote a culture where every voice is heard, valued, and respected.</p>
        </div>
    )
}
